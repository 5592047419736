/* eslint-disable no-plusplus */
class GenesisCogFunctions {

  constructor() {
    this.pineRma = GenesisCogFunctions.pineRma;
    this.simpleMovingAverage = GenesisCogFunctions.simpleMovingAverage;
    this.calculateEma = GenesisCogFunctions.calculateEma;
    this.calcAccDist = GenesisCogFunctions.calcAccDist;
    this.round = GenesisCogFunctions.round;
  }

  static round(value) {
    return Math.round((value + Number.EPSILON) * 1000) / 1000;
  }

  static pineRma(src, length) {
    const alpha = 1 / (length);
    const sumArray = [];
    for (let i = 0; i < src.length; i++) {
      const srcValue = this.round(src[i]/length);
      if (i === 0) {
        sumArray.push(srcValue); // Use the first value as initial RMA
      } else {
        const previousSum = this.round(sumArray[i - 1]);
        sumArray.push(alpha * srcValue + (1 - alpha) * previousSum);
      }
    }
    return sumArray;
  }

  static simpleMovingAverage(src, length) {
    const sma = [];
    for (let i = 0; i < src.length; i++) {
      if (i < length - 1) {
        sma.push(null); // Not enough data to calculate SMA
      } else {
        const sum = src.slice(i - length + 1, i + 1).reduce((a, b) => a + b, 0);
        sma.push(this.round(sum / length));
      }
    }
    return sma;
  }

  static calculateEma(src, length) {
    const alpha = 2 / (length + 1);
    const emaArray = [];

    for (let i = 0; i < src.length; i++) {
      if (i === 0) {
        emaArray.push(this.round(src[i])); // Use the first value as the initial EMA
      } else {
        const previousEma = emaArray[i - 1];
        emaArray.push(this.round(alpha * src[i] + (1 - alpha) * previousEma));
      }
    }
    return emaArray;
  }

  static calcAccDist(accdistHistory) {
    const updatedAccDist = [];

    // Start with an initial sum of 0 for the cumulative A/D values
    accdistHistory.reduce((sum, value, index) => {
      const {high, low, close, volume} = value;

      // Initialize the current cumulative sum to the previous total
      let currentSum = sum;

      /**
       * Money Flow Multiplier (MFM):
       * - Determines the relationship between the closing price and the high/low range.
       * - Formula: MFM = ((Close - Low) - (High - Close)) / (High - Low)
       * - Simplifies to: MFM = (2 * Close - Low - High) / (High - Low)
       *
       * When MFM is positive, it indicates buying pressure (close near the high).
       * When MFM is negative, it indicates selling pressure (close near the low).
       */

      /**
       * Accumulation/Distribution Value:
       * - Combines the MFM with the volume to determine the A/D value for the current period.
       * - Formula: A/D Value = Volume * MFM
       * - Handle cases where High === Low to avoid division by zero.
       */
      // const accdistValue =
      //   (close === high && close === low) || high === low
      //     ? 0 // No price movement or invalid range; set A/D value to 0
      //     : (volume * (2 * close - low - high)) / (high - low); // A/D value based on MFM and volume
      const accdistValue = (volume * (2 * close - low - high)) / (high - low)

      // Add the current A/D value to the cumulative sum
      currentSum += accdistValue;

      // Store the cumulative A/D value in the updated array
      updatedAccDist.push(this.round(currentSum));

      // Return the updated cumulative sum for the next iteration
      return currentSum;
    }, 0); // Initial cumulative sum is 0
    // Update the A/D index with the calculated array
    return updatedAccDist;
  }

  pineRsi(x, y) {
    const u = [];
    const d = [];

    // Calculate upward and downward changes
    for (let i = 1; i < x.length; i++) {
      const change = this.round(x[i] - x[i - 1]);
      u.push(Math.max(change, 0));
      d.push(Math.max(-change, 0));
    }

    // Use Wilder's RMA for averaging
    const rmaU = this.pineRma(u, y);
    const rmaD = this.pineRma(d, y);

    // Compute RSI
    const rs = rmaU.map((value, index) => this.round(rmaD[index] === 0 ? 1 : value / (rmaD[index] || 1)));
    const rsi = rs.map((value) => this.round(100 - 100 / (1 + value)));
    // console.log("rsi", rsi)

    return rsi;
  }

  calculateMacd(src, fastLength, slowLength, signalLength) {
    // Step 1: Calculate the Fast EMA
    const fastEma = this.calculateEma(src, fastLength);

    // Step 2: Calculate the Slow EMA
    const slowEma = this.calculateEma(src, slowLength);

    // Step 3: Calculate the MACD Line (Fast EMA - Slow EMA)
    const macdLine = fastEma.map((value, index) => this.round(value - (slowEma[index] || 0)));

    // Step 4: Calculate the Signal Line (EMA of MACD Line)
    const signalLine = this.calculateEma(macdLine, signalLength);

    // Step 5: Calculate the MACD Histogram (MACD Line - Signal Line)
    const histogram = macdLine.map((value, index) => this.round(value - (signalLine[index] || 0)));

    // Return the components of MACD
    return {
      macdLine,
      signalLine,
      histogram,
    };
  }
}

export default GenesisCogFunctions;


