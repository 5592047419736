/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import businessDays from 'business-days-js';

import GenesisCogFunctions from './genesis-functions';

const bDays = businessDays();

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/New_York');

const today = dayjs().tz('America/New_York').set('hour', 0);

const displayLowerDebugLog = true;
const displayUpperDebugLog = true;
const useHeikinAshiDefault = true;
const restrictAccDist = true;

const subtractDays = (inputDate, days, {excludeInitialDate = true}) => {
  let counter = 0;
  let dayJsObj = inputDate;
  if (excludeInitialDate) {
    dayJsObj = dayJsObj.subtract(1, 'day');
  }

  while (counter < days) {
    if (bDays.check(dayJsObj)) {
      counter++;
    }
    if (counter < days) {
      dayJsObj = dayJsObj.subtract(1, 'day');
    }
  }
  return dayJsObj;
};

export const GenesisCogMain = (pjs) => ({
  name: 'Genesis COG Signal',
  metainfo: {
    _metainfoVersion: 51,
    id: 'GenesisMain@tv-basicstudies-1',
    description: 'Genesis COG Signal',
    shortDescription: 'Genesis COG Signal',
    is_price_study: true,
    isCustomIndicator: true,
    linkedToSeries: true,
    plots: [
      // Strong Buy
      {
        id: 'strong_buy',
        type: 'shapes',
        plottype: 'shape_arrow_up',
        location: 'BelowBar',
        color: '#00ff00',
      },
      // Buy
      {
        id: 'buy',
        type: 'shapes',
        plottype: 'shape_arrow_up',
        location: 'BelowBar',
        color: '#009600',
      },
      // Strong Sell
      {
        id: 'strong_sell',
        type: 'shapes',
        plottype: 'shape_arrow_down',
        location: 'AboveBar',
        color: '#ff0000',
      },
      // Sell
      {
        id: 'sell',
        type: 'shapes',
        plottype: 'shape_arrow_down',
        location: 'AboveBar',
        color: '#ffa600',
      },
    ],
    defaults: {
      palettes: {},
      styles: {
        strong_buy: {
          location: 'BelowBar',
          text: '',
          color: '#00ff00', // Green
          textColor: '#00ff00', // Green
          plottype: 'shape_arrow_up',
        },
        buy: {
          location: 'BelowBar',
          text: '',
          color: '#009600', // Dark Green
          textColor: '#009600', // Dark Green
          plottype: 'shape_arrow_up',
        },
        strong_sell: {
          location: 'AboveBar',
          text: '',
          color: '#ff0000', // Red
          textColor: '#ff0000', // Red
          plottype: 'shape_arrow_down',
        },
        sell: {
          location: 'AboveBar',
          text: '',
          color: '#ffa600', // Orange
          textColor: '#ffa600', // Orange
          plottype: 'shape_arrow_down',
        },
      },
      precision: 4,
      inputs: {
        rsi_length: 14,
        macd_fast: 12,
        macd_slow: 26,
        macd_signal: 9,
        score_threshold: 3.0,
        strong_threshold: 4.0,
        max_history: 205,
        showDebugLog: displayUpperDebugLog,
        useHeikinAshi: useHeikinAshiDefault,
      },
    },
    styles: {
      strong_buy: {
        title: 'Strong Buy',
        text: '',
      },
      buy: {
        title: 'Buy',
        text: '',
      },
      strong_sell: {
        title: 'Strong Sell',
        text: '',
      },
      sell: {
        title: 'Sell',
        text: '',
      },
      test: {
        title: 'Test',
      },
    },
    inputs: [
      {id: 'rsi_length', name: 'RSI Length', type: 'integer', defval: 14},
      {id: 'macd_fast', name: 'MACD Fast', type: 'integer', defval: 12},
      {id: 'macd_slow', name: 'MACD Slow', type: 'integer', defval: 26},
      {id: 'macd_signal', name: 'MACD Signal', type: 'integer', defval: 9},
      {id: 'score_threshold', name: 'Score Threshold', type: 'float', defval: 3.0},
      {id: 'strong_threshold', name: 'Strong Threshold', type: 'float', defval: 4.0},
      {id: 'max_history', name: 'Maximum Number of Bars for AccDist', type: 'integer', defval: 205},
      {
        id: 'showDebugLog',
        name: 'Show Debug Log for last bar (console.log)',
        type: 'bool',
        defval: displayUpperDebugLog,
      },
      {id: 'useHeikinAshi', name: 'Use Heikin Ashi', type: 'bool', defval: useHeikinAshiDefault},
    ],
    format: {
      type: 'price',
      precision: 4,
    },
  },
  constructor: function () {
    this._maxBarInHistory = subtractDays(today, 210, {excludeInitialDate: false});
    this.accdistHistory = [];
    this.accdist = [];
    this.symbol = null;
    this.genesisFunctions = new GenesisCogFunctions();

    this.pineRma = this.genesisFunctions.pineRma;
    this.pineRsi = this.genesisFunctions.pineRsi;
    this.simpleMovingAverage = this.genesisFunctions.simpleMovingAverage;
    this.calculateEma = this.genesisFunctions.calculateEma;
    this.calculateMacd = this.genesisFunctions.calculateMacd;
    this.calcAccDist = this.genesisFunctions.calcAccDist;
    this.round = this.genesisFunctions.round;

    this.init = function (context, inputCallback) {
      const useHeikinAshi = inputCallback(8);
      const maxHistory = inputCallback(6);
      const {ticker, tickerid} = context?.symbol;
      this._maxBarInHistory =
        maxHistory !== 205 ? subtractDays(today, maxHistory + 5, {excludeInitialDate: false}) : this._maxBarInHistory;

      if (ticker && !useHeikinAshi) {
        const symbol = '={"symbol":"ACGL"}'.replace('ACGL', ticker);
        if (symbol !== tickerid) {
          const period = pjs.Std.period(context);
          context.new_sym(symbol, period);
          if (!useHeikinAshi) {
            context.select_sym(1);
          }
        }
      }
    };

    this.removeUnlimited = function (history, newestValue, index) {
      const cleanHistory = history?.length
        ? [...history]
            .slice(0, index)
            .filter((price) => !Number.isNaN(price))
            .map((price) => this.round(price))
        : [];
      if (Array.isArray(cleanHistory) && cleanHistory.length > 0 && cleanHistory[cleanHistory.length - 1] !== newestValue) {
        cleanHistory.push(this.round(newestValue));
      }
      return cleanHistory;
    };

    this.main = function (context, inputCallback) {
      this._context = context;
      const {isLastBar} = this._context.symbol;
      const logType = '[Upper Indicator]';

      const rsiLength = inputCallback(0);
      const macdFast = inputCallback(1);
      const macdSlow = inputCallback(2);
      const macdSignal = inputCallback(3);
      const scoreThreshold = inputCallback(4);
      const strongThreshold = inputCallback(5);
      const maxHistory = inputCallback(6);
      const enableDebug = inputCallback(7);
      const useHeikinAshi = inputCallback(8);
      const defaultBarValue = [0, 0, 0, 0];

      if (useHeikinAshi && !this._context.is_main_symbol(this._context)) {
        this._context.select_sym(0);
      } else if (!useHeikinAshi && this._context.is_main_symbol(this._context)) {
        this._context.select_sym(1);
      }

      if (this.symbol !== this._context.symbol.ticker) {
        this.symbol = this._context.symbol.ticker;
        this.accdistHistory = [];
        this.accdist = [];
      }
      const close = this._context.new_unlimited_var(pjs.Std.close(this._context));
      const volume = this._context.new_unlimited_var(pjs.Std.volume(this._context));
      const high = this._context.new_unlimited_var(pjs.Std.high(this._context));
      const low = this._context.new_unlimited_var(pjs.Std.low(this._context));

      this._enableDebug = enableDebug;
      this._isLastBar = isLastBar;
      this._showDebugLog = enableDebug && isLastBar;
      if (close.indexOf(pjs.Std.time(this._context, pjs.Std.period(context))) === -1) {
        return defaultBarValue;
      }

      if (Number.isNaN(close.get())) {
        return defaultBarValue;
      }
      if (this._showDebugLog) {
        console.log(logType, ' symbol:', this._context.symbol.ticker);
        console.log(logType, ' useHeikinAshi:', useHeikinAshi);
        console.log(logType, ' close:', close.get());
      }
      const index = this._context.new_unlimited_var(this._context.symbol.index);
      const closeHistory = this.removeUnlimited(close._hist, close.get(), this._context.symbol.index);
      const rsi = this._context.new_unlimited_var(
        closeHistory?.length ? this.pineRsi(closeHistory, rsiLength)[index.get() - 1] : 0,
      );

      if (!restrictAccDist) {
        this.accdistHistory = [
          ...this.accdistHistory,
          {
            close: close.get(),
            volume: volume.get(),
            high: high.get(),
            low: low.get(),
          },
        ];
        this.accdist = this.calcAccDist(this.accdistHistory);
      } else {
        const time = this._context.new_unlimited_var(pjs.Std.time(this._context, this._context.symbol.period));
        const symbol = this._context.symbol.ticker;
        if (time.get() && !Number.isNaN(time.get()) && this._maxBarInHistory) {
          const currentBarDate = dayjs.tz(time.get(), 'America/New_York');
          if (currentBarDate.isAfter(this._maxBarInHistory)) {
            if (!Object.keys(this.accdist ?? {})?.length) {
              this._firstBarIndex = index.get() ?? 0;
            }
            const i = index.get() ?? 0;
            const calcMaxHistory = maxHistory + 5;
            const newIndex = calcMaxHistory - this._firstBarIndex + (i - calcMaxHistory);
            if (!this.accdistHistory?.find((his) => his.originalIndex === index.get())) {
              this.accdistHistory = [
                ...this.accdistHistory,
                {
                  index: newIndex,
                  originalIndex: i,
                  close: close.get(),
                  volume: volume.get(),
                  high: high.get(),
                  low: low.get(),
                  symbol: symbol,
                },
              ];
              this.accdist = this.calcAccDist(this.accdistHistory);
            }
          }
        }
      }
      const macd = closeHistory?.length ? this.calculateMacd(closeHistory, macdFast, macdSlow, macdSignal) : {};
      const macdLine1 = this.round(macd?.macdLine?.[macd?.macdLine?.length - 1]);
      const macdLine2 = this.round(macd?.macdLine?.[macd?.macdLine?.length - 2]);
      const macdSlope = this._context.new_unlimited_var(this.round(macdLine1 - macdLine2));
      const accDist1 = this.round(this.accdist[this.accdist?.length - 1]);
      const accDist2 = this.round(this.accdist[this.accdist?.length - 2]);
      const adSlope = this._context.new_unlimited_var(this.round(((accDist1 - accDist2) / accDist2) * 100));

      if (this._showDebugLog) {
        const last20 = closeHistory?.length >= 20 ? closeHistory.slice(closeHistory.length - 20) : [];
        const rsiHistory = this.removeUnlimited(rsi._hist, rsi.get(), this._context.symbol.index);
        const last20RSI = rsiHistory?.length >= 20 ? rsiHistory.slice(rsiHistory.length - 20) : [];
        const adSlopeHistory = this.removeUnlimited(adSlope._hist, adSlope.get(), this._context.symbol.index);
        console.log(logType, ' adSlopeHistory', adSlopeHistory);
        const last20SignalLine =
          macd?.signalLine?.length >= 20 ? macd?.signalLine?.slice(macd?.signalLine?.length - 20) : [];
        const last20Histogram =
          macd?.histogram?.length >= 20 ? macd?.histogram?.slice(macd?.histogram?.length - 20) : [];
        const macdHistory = this.removeUnlimited(macdSlope._hist, macdSlope.get(), this._context.symbol.index);
        const last20MacdSlope = macdHistory?.length >= 20 ? macdHistory?.slice(macdHistory?.length - 20) : [];
        const last20MACDLine = macd?.macdLine?.length >= 20 ? macd?.macdLine.slice(macd?.macdLine.length - 20) : [];
        const last20AccDist = this.accdist?.length >= 20 ? this.accdist.slice(this.accdist.length - 20) : [];
        const last20adSlope = adSlopeHistory?.length >= 20 ? adSlopeHistory?.slice(adSlopeHistory?.length - 20) : [];
        console.log(logType, ' (Last 20) Close:', last20);
        console.log(logType, ' (Last 20) RSI:', last20RSI);
        console.log(logType, ' (Last 20) MACDLine:', last20MACDLine);
        console.log(logType, ' (Last 20) MACDSignalLine:', last20SignalLine);
        console.log(logType, ' (Last 20) MACDhistogram:', last20Histogram);
        console.log(logType, ' (Last 20) MacdSlope:', last20MacdSlope);
        console.log(logType, ' (Last 20) AccDist:', last20AccDist);
        console.log(logType, ' (Last 20) adSlope:', last20adSlope);
        console.log(logType, ' rsi:', rsi.get());
        console.log(logType, ' macdcalc', macd);
        console.log(logType, ' MACDfast:', macd?.macdLine?.[macd?.macdLine?.length - 1]);
        console.log(logType, ' MACDfast[1]:', macd?.macdLine?.[index - 1]);
        console.log(logType, ' MACDslow:', macd?.signalLine?.[index - 1]);
        console.log(logType, ' MACDhist:', macd?.histogram?.[index - 1]);
        console.log(logType, ' macdSlope:', macdSlope.get());
        console.log(logType, ' ad:', this.accdist[this.accdist?.length - 1]);
        console.log(logType, ' ad[1]:', this.accdist[this.accdist?.length - 2]);
        console.log(logType, ' adSlope:', adSlope.get());
      }
      // Scoring System
      const rsiScore = this._context.new_unlimited_var(
        rsi.get() < 35 ? 2.0 : rsi.get() < 45 ? 1.0 : rsi.get() > 65 ? -2.0 : rsi.get() > 55 ? -1.0 : 0.0,
      );
      const macdScore = this._context.new_unlimited_var(
        macdSlope.get() >= 0.1
          ? 1.5
          : macdSlope.get() >= 0.03
          ? 1.0
          : macdSlope.get() <= -0.1
          ? -1.5
          : macdSlope.get() <= -0.03
          ? -1.0
          : 0.0,
      );
      const adScore = this._context.new_unlimited_var(
        adSlope.get() > 0.005 ? 1.0 : adSlope.get() < -0.005 ? -1.0 : 0.0,
      );
      const totalScore = this._context.new_unlimited_var(rsiScore.get() + macdScore.get() + adScore.get());

      if (this._showDebugLog) {
        // RSI
        const rsiScoreHistory = this.removeUnlimited(rsiScore._hist, rsiScore.get(), this._context.symbol.index);
        const last20RSI = rsiScoreHistory?.length >= 20 ? rsiScoreHistory.slice(rsiScoreHistory.length - 20) : [];

        // MacD
        const macDScoreHistory = this.removeUnlimited(macdScore._hist, macdScore.get(), this._context.symbol.index);
        const last20macDScore =
          macDScoreHistory?.length >= 20 ? macDScoreHistory.slice(macDScoreHistory.length - 20) : [];

        // AD
        const adScoreHistory = this.removeUnlimited(adScore._hist, adScore.get(), this._context.symbol.index);
        const last20adScore = adScoreHistory?.length >= 20 ? adScoreHistory.slice(adScoreHistory.length - 20) : [];

        // Total Score
        const totalScoreHistory = this.removeUnlimited(totalScore._hist, totalScore.get(), this._context.symbol.index);
        const totalScoreLast20 =
          totalScoreHistory?.length >= 20 ? totalScoreHistory.slice(totalScoreHistory.length - 20) : [];

        console.log(logType, ' (Last 20) RSIscore:', last20RSI);
        console.log(logType, ' (Last 20) macdScore:', last20macDScore);
        console.log(logType, ' (Last 20) adScore:', last20adScore);
        console.log(logType, ' (Last 20) totalScore:', totalScoreLast20);
      }

      if (!totalScore.get) {
        return defaultBarValue;
      }
      if (Number.isNaN(totalScore.get()) || Number.isNaN(totalScore.get(1))) {
        return defaultBarValue;
      }

      // Signal Generation
      const buySignal = totalScore.get() >= scoreThreshold && totalScore.get(1) < scoreThreshold;
      const strongBuySignal = totalScore.get() >= strongThreshold && totalScore.get(1) < strongThreshold;
      const sellSignal = totalScore.get() <= -scoreThreshold && totalScore.get(1) > -scoreThreshold;
      const strongSellSignal = totalScore.get() <= -strongThreshold && totalScore.get(1) > -strongThreshold;

      if (this._showDebugLog) {
        console.log(logType, ' buySignal:', buySignal);
        console.log(logType, ' totalScore:', totalScore.get());
        console.log(logType, ' total_score >= score_threshold and total_score[1] < score_threshold:', buySignal);
        console.log(logType, ' total_score[1] < score_threshold:', totalScore.get(1) < scoreThreshold);
        console.log(logType, ' total_score >= score_threshold:', totalScore.get() >= scoreThreshold);
        console.log(logType, ' strongBuySignal:', strongBuySignal);
        console.log(
          logType,
          ' total_score >= strong_threshold and total_score[1] < strong_threshold:',
          strongBuySignal,
        );
        console.log(logType, ' total_score >= strong_threshold:', totalScore.get() >= strongThreshold);
        console.log(logType, ' total_score[1] < strong_threshold:', totalScore.get(1) < strongThreshold);
        console.log(logType, ' sellSignal:', sellSignal);
        console.log(logType, ' total_score <= -score_threshold and total_score[1] > -score_threshold:', sellSignal);
        console.log(logType, ' total_score <= -score_threshold:', totalScore.get() <= -scoreThreshold);
        console.log(logType, ' total_score[1] > -score_threshold:', totalScore.get(1) > -scoreThreshold);
        console.log(logType, ' strongSellSignal:', strongSellSignal);
        console.log(
          logType,
          ' total_score <= -strong_threshold and total_score[1] > -strong_threshold:',
          strongSellSignal,
        );
        console.log(logType, ' total_score <= -strong_threshold:', totalScore.get() <= -strongThreshold);
        console.log(logType, ' total_score[1] > -strong_threshold:', totalScore.get(1) > -strongThreshold);
      }

      // Plot Signals
      const plotValues = [0, 0, 0, 0];
      if (buySignal || strongBuySignal) {
        if (strongBuySignal) {
          plotValues[0] = 1;
          plotValues[1] = 0;
        } else if (buySignal) {
          plotValues[0] = 0;
          plotValues[1] = 1;
        }
      }

      if (sellSignal || strongSellSignal) {
        if (strongSellSignal) {
          plotValues[2] = 1;
          plotValues[3] = 0;
        } else if (sellSignal) {
          plotValues[2] = 0;
          plotValues[3] = 1;
        }
      }

      return plotValues;
    };
  },
});

export const GenesisCogLower = (pjs) => ({
  name: 'Genesis COG Rank',
  metainfo: {
    _metainfoVersion: 51,
    id: 'GenesisLower@tv-basicstudies-1',
    description: 'Genesis COG Rank',
    shortDescription: 'Genesis COG Rank',
    is_price_study: false,
    isCustomIndicator: true,
    linkedToSeries: false,
    plots: [
      {
        id: 'bars',
        type: 'line',
      },
      {
        id: 'bars_colorer',
        type: 'colorer',
        target: 'bars',
        palette: 'paletteId1',
      },
      // Strong Upper Threshold
      {
        id: 'strong_upper_threshold',
        type: 'line',
        color: '#00ff00',
      },
      // Upper Threshold
      {
        id: 'upper_threshold',
        type: 'line',
        color: '#00ff00',
      },
      // Lower Threshold
      {
        id: 'lower_threshold',
        type: 'line',
        color: '#ffa600',
      },
      // Strong Lower Threshold
      {
        id: 'strong_lower_threshold',
        type: 'line',
        color: '#ff0000',
      },
      // Zero Line
      {
        id: 'zero_line',
        type: 'line',
        color: '#404040',
      },
    ],
    palettes: {
      paletteId1: {
        colors: [
          {name: 'Buy', color: '#009600'}, // Bright Green
          {name: 'Strong Buy', color: '#00ff00'}, // Dark Green
          {name: 'Sell', color: '#ffa600'}, // Orange
          {name: 'Strong Sell', color: '#ff0000'}, // Red
          {name: 'Neutral', color: '#404040'}, // Gray
        ],
        valToIndex: {0: 0, 1: 1, 2: 2, 3: 3, 4: 4},
      },
    },
    defaults: {
      palettes: {
        paletteId1: {
          colors: [
            {name: 'Buy', color: '#009600', width: 3, style: 1}, // Bright Green
            {name: 'Strong Buy', color: '#00ff00', width: 3, style: 1}, // Dark Green
            {name: 'Sell', color: '#ffa600', width: 3, style: 1}, // Orange
            {name: 'Strong Sell', color: '#ff0000', width: 3, style: 1}, // Red
            {name: 'Neutral', color: '#404040', width: 3, style: 1}, // Gray
          ],
        },
      },
      styles: {
        bars: {
          linestyle: 0,
          width: 6,
          plottype: 5,
          trackPrice: false,
          transparency: 0,
          visible: true,
          linewidth: 3,
        },
        // Strong Upper Threshold
        strong_upper_threshold: {
          linestyle: 0,
          visible: true,
          linewidth: 3,
          plottype: 0,
          trackPrice: false,
          color: '#00ff00', // Bright Green
        },
        // Upper Threshold
        upper_threshold: {
          linestyle: 0,
          visible: true,
          linewidth: 3,
          plottype: 0,
          trackPrice: false,
          color: '#009600', // Dark Green
        },
        // Lower Threshold
        lower_threshold: {
          linestyle: 0,
          visible: true,
          linewidth: 3,
          plottype: 0,
          trackPrice: false,
          color: '#ffa600', // Orange
        },
        // Strong Lower Threshold
        strong_lower_threshold: {
          linestyle: 0,
          visible: true,
          linewidth: 3,
          plottype: 0,
          trackPrice: false,
          color: '#ff0000', // Red
        },
        // Zero Line
        zero_line: {
          linestyle: 0,
          visible: true,
          linewidth: 3,
          plottype: 0,
          trackPrice: false,
          color: '#404040',
        },
      },
      precision: 4,
      inputs: {
        rsi_length: 14,
        macd_fast: 12,
        macd_slow: 26,
        macd_signal: 9,
        score_threshold: 3.0,
        strong_threshold: 4.0,
        max_history: 205,
        showDebugLog: displayLowerDebugLog,
        useHeikinAshi: useHeikinAshiDefault,
      },
    },
    styles: {
      bars: {
        title: 'Plot Score Histogram',
        histogramBase: 0,
      },
    },
    inputs: [
      {id: 'rsi_length', name: 'RSI Length', type: 'integer', defval: 14},
      {id: 'macd_fast', name: 'MACD Fast', type: 'integer', defval: 12},
      {id: 'macd_slow', name: 'MACD Slow', type: 'integer', defval: 26},
      {id: 'macd_signal', name: 'MACD Signal', type: 'integer', defval: 9},
      {id: 'score_threshold', name: 'Score Threshold', type: 'float', defval: 3.0},
      {id: 'strong_threshold', name: 'Strong Threshold', type: 'float', defval: 4.0},
      {id: 'max_history', name: 'Maximum Number of Bars for AccDist', type: 'integer', defval: 205},
      {
        id: 'showDebugLog',
        name: 'Show Debug Log for last bar (console.log)',
        type: 'bool',
        defval: displayLowerDebugLog,
      },
      {id: 'useHeikinAshi', name: 'Use Heikin Ashi', type: 'bool', defval: useHeikinAshiDefault},
    ],
    format: {
      type: 'price',
      precision: 4,
    },
  },
  constructor: function () {
    this._maxBarInHistory = subtractDays(today, 210, {excludeInitialDate: false});
    this.accdistHistory = [];
    this.accdist = [];
    this.symbol = null;
    this.genesisFunctions = new GenesisCogFunctions();

    this.pineRma = this.genesisFunctions.pineRma;
    this.pineRsi = this.genesisFunctions.pineRsi;
    this.simpleMovingAverage = this.genesisFunctions.simpleMovingAverage;
    this.calculateEma = this.genesisFunctions.calculateEma;
    this.calculateMacd = this.genesisFunctions.calculateMacd;
    this.calcAccDist = this.genesisFunctions.calcAccDist;
    this.round = this.genesisFunctions.round;

    this.init = function (context, inputCallback) {
      const useHeikinAshi = inputCallback(8);
      const maxHistory = inputCallback(6);
      const {ticker, tickerid} = context?.symbol;
      this._maxBarInHistory =
        maxHistory !== 205 ? subtractDays(today, maxHistory + 5, {excludeInitialDate: false}) : this._maxBarInHistory;

      if (ticker && !useHeikinAshi) {
        const symbol = '={"symbol":"ACGL"}'.replace('ACGL', ticker);
        if (symbol !== tickerid) {
          const period = pjs.Std.period(context);
          context.new_sym(symbol, period);
          if (!useHeikinAshi) {
            context.select_sym(1);
          }
        }
      }
    };
    this.removeUnlimited = function (history, newestValue, index) {
      const cleanHistory = history?.length
        ? [...history]
            .slice(0, index)
            .filter((price) => !Number.isNaN(price))
            .map((price) => this.round(price))
        : [];
      if (Array.isArray(cleanHistory) && cleanHistory.length > 0 && cleanHistory[cleanHistory.length - 1] !== newestValue) {
        cleanHistory.push(this.round(newestValue));
      }
      return cleanHistory;
    };
    this.main = function (context, inputCallback) {
      this._context = context;
      const {isLastBar} = this._context.symbol;
      const logType = '[Lower Indicator]';
      const rsiLength = inputCallback(0);
      const macdFast = inputCallback(1);
      const macdSlow = inputCallback(2);
      const macdSignal = inputCallback(3);
      const scoreThreshold = inputCallback(4);
      const strongThreshold = inputCallback(5);
      const maxHistory = inputCallback(6);
      const enableDebug = inputCallback(7);
      const useHeikinAshi = inputCallback(8);
      const defaultBarValue = [0, 4, strongThreshold, scoreThreshold, -scoreThreshold, -strongThreshold, 0];

      this._enableDebug = enableDebug;
      this._isLastBar = isLastBar;
      this._showDebugLog = enableDebug && isLastBar;

      if (useHeikinAshi && !this._context.is_main_symbol(this._context)) {
        this._context.select_sym(0);
      } else if (!useHeikinAshi && this._context.is_main_symbol(this._context)) {
        this._context.select_sym(1);
      }

      if (this.symbol !== this._context.symbol.ticker) {
        this.symbol = this._context.symbol.ticker;
        this.accdistHistory = [];
        this.accdist = [];
      }
      const close = this._context.new_unlimited_var(pjs.Std.close(this._context));
      const volume = this._context.new_unlimited_var(pjs.Std.volume(this._context));
      const high = this._context.new_unlimited_var(pjs.Std.high(this._context));
      const low = this._context.new_unlimited_var(pjs.Std.low(this._context));

      this._enableDebug = enableDebug;
      this._isLastBar = isLastBar;
      this._showDebugLog = enableDebug && isLastBar;
      if (close.indexOf(pjs.Std.time(this._context, pjs.Std.period(context))) === -1) {
        return defaultBarValue;
      }

      if (Number.isNaN(close.get())) {
        return defaultBarValue;
      }
      if (this._showDebugLog) {
        console.log(logType, ' symbol:', this._context.symbol.ticker);
        console.log(logType, ' useHeikinAshi:', useHeikinAshi);
        console.log(logType, ' close:', close.get());
      }
      const index = this._context.new_unlimited_var(this._context.symbol.index);
      const closeHistory = this.removeUnlimited(close._hist, close.get(), this._context.symbol.index);
      const rsi = this._context.new_unlimited_var(
        closeHistory?.length ? this.pineRsi(closeHistory, rsiLength)[index.get() - 1] : 0,
      );

      if (!restrictAccDist) {
        this.accdistHistory = [
          ...this.accdistHistory,
          {
            close: close.get(),
            volume: volume.get(),
            high: high.get(),
            low: low.get(),
          },
        ];
        this.accdist = this.calcAccDist(this.accdistHistory);
      } else {
        const time = this._context.new_unlimited_var(pjs.Std.time(this._context, this._context.symbol.period));
        const symbol = this._context.symbol.ticker;
        if (time.get() && !Number.isNaN(time.get()) && this._maxBarInHistory) {
          const currentBarDate = dayjs.tz(time.get(), 'America/New_York');
          if (currentBarDate.isAfter(this._maxBarInHistory)) {
            if (!Object.keys(this.accdist ?? {})?.length) {
              this._firstBarIndex = index.get() ?? 0;
            }
            const i = index.get() ?? 0;
            const calcMaxHistory = maxHistory + 5;
            const newIndex = calcMaxHistory - this._firstBarIndex + (i - calcMaxHistory);
            if (!this.accdistHistory?.find((his) => his.originalIndex === index.get())) {
              this.accdistHistory = [
                ...this.accdistHistory,
                {
                  index: newIndex,
                  originalIndex: i,
                  close: close.get(),
                  volume: volume.get(),
                  high: high.get(),
                  low: low.get(),
                  symbol: symbol,
                },
              ];
              this.accdist = this.calcAccDist(this.accdistHistory);
            }
          }
        }
      }

      const macd = closeHistory?.length ? this.calculateMacd(closeHistory, macdFast, macdSlow, macdSignal) : {};
      const macdLine1 = this.round(macd?.macdLine?.[macd?.macdLine?.length - 1]);
      const macdLine2 = this.round(macd?.macdLine?.[macd?.macdLine?.length - 2]);
      const macdSlope = this._context.new_unlimited_var(this.round(macdLine1 - macdLine2));
      const accDist1 = this.round(this.accdist[this.accdist?.length - 1]);
      const accDist2 = this.round(this.accdist[this.accdist?.length - 2]);
      const adSlope = this._context.new_unlimited_var(this.round(((accDist1 - accDist2) / accDist2) * 100));

      if (this._showDebugLog) {
        const last20 = closeHistory?.length >= 20 ? closeHistory.slice(closeHistory.length - 20) : [];
        const rsiHistory = this.removeUnlimited(rsi._hist, rsi.get(), this._context.symbol.index);
        const last20RSI = rsiHistory?.length >= 20 ? rsiHistory.slice(rsiHistory.length - 20) : [];
        const adSlopeHistory = this.removeUnlimited(adSlope._hist, adSlope.get(), this._context.symbol.index);
        const last20SignalLine =
          macd?.signalLine?.length >= 20 ? macd?.signalLine?.slice(macd?.signalLine?.length - 20) : [];
        const last20Histogram =
          macd?.histogram?.length >= 20 ? macd?.histogram?.slice(macd?.histogram?.length - 20) : [];
        const macdHistory = this.removeUnlimited(macdSlope._hist, macdSlope.get(), this._context.symbol.index);
        const last20MacdSlope = macdHistory?.length >= 20 ? macdHistory?.slice(macdHistory?.length - 20) : [];
        const last20MACDLine = macd?.macdLine?.length >= 20 ? macd?.macdLine.slice(macd?.macdLine.length - 20) : [];
        const last20AccDist = this.accdist?.length >= 20 ? this.accdist.slice(this.accdist.length - 20) : [];
        const last20adSlope = adSlopeHistory?.length >= 20 ? adSlopeHistory?.slice(adSlopeHistory?.length - 20) : [];
        console.log(logType, ' (Last 20) Close:', last20);
        console.log(logType, ' (Last 20) RSI:', last20RSI);
        console.log(logType, ' (Last 20) MACDLine:', last20MACDLine);
        console.log(logType, ' (Last 20) MACDSignalLine:', last20SignalLine);
        console.log(logType, ' (Last 20) MACDhistogram:', last20Histogram);
        console.log(logType, ' (Last 20) MacdSlope:', last20MacdSlope);
        console.log(logType, ' (Last 20) AccDist:', last20AccDist);
        console.log(logType, ' (Last 20) adSlope:', last20adSlope);
        console.log(logType, ' rsi:', rsi.get());
        console.log(logType, ' macdcalc', macd);
        console.log(logType, ' MACDfast:', macd?.macdLine?.[macd?.macdLine?.length - 1]);
        console.log(logType, ' MACDfast[1]:', macd?.macdLine?.[index - 1]);
        console.log(logType, ' MACDslow:', macd?.signalLine?.[index - 1]);
        console.log(logType, ' MACDhist:', macd?.histogram?.[index - 1]);
        console.log(logType, ' macdSlope:', macdSlope.get());
        console.log(logType, ' ad:', this.accdist[this.accdist?.length - 1]);
        console.log(logType, ' ad[1]:', this.accdist[this.accdist?.length - 2]);
        console.log(logType, ' adSlope:', adSlope.get());
      }
      // Scoring System
      const rsiScore = this._context.new_unlimited_var(
        rsi.get() < 35 ? 2.0 : rsi.get() < 45 ? 1.0 : rsi.get() > 65 ? -2.0 : rsi.get() > 55 ? -1.0 : 0.0,
      );
      const macdScore = this._context.new_unlimited_var(
        macdSlope.get() >= 0.1
          ? 1.5
          : macdSlope.get() >= 0.03
          ? 1.0
          : macdSlope.get() <= -0.1
          ? -1.5
          : macdSlope.get() <= -0.03
          ? -1.0
          : 0.0,
      );
      const adScore = this._context.new_unlimited_var(
        adSlope.get() > 0.005 ? 1.0 : adSlope.get() < -0.005 ? -1.0 : 0.0,
      );
      const totalScore = this._context.new_unlimited_var(rsiScore.get() + macdScore.get() + adScore.get());

      if (this._showDebugLog) {
        // RSI
        const rsiScoreHistory = this.removeUnlimited(rsiScore._hist, rsiScore.get(), this._context.symbol.index);
        const last20RSI = rsiScoreHistory?.length >= 20 ? rsiScoreHistory.slice(rsiScoreHistory.length - 20) : [];

        // MacD
        const macDScoreHistory = this.removeUnlimited(macdScore._hist, macdScore.get(), this._context.symbol.index);
        const last20macDScore =
          macDScoreHistory?.length >= 20 ? macDScoreHistory.slice(macDScoreHistory.length - 20) : [];

        // AD
        const adScoreHistory = this.removeUnlimited(adScore._hist, adScore.get(), this._context.symbol.index);
        const last20adScore = adScoreHistory?.length >= 20 ? adScoreHistory.slice(adScoreHistory.length - 20) : [];

        // Total Score
        const totalScoreHistory = this.removeUnlimited(totalScore._hist, totalScore.get(), this._context.symbol.index);
        const totalScoreLast20 =
          totalScoreHistory?.length >= 20 ? totalScoreHistory.slice(totalScoreHistory.length - 20) : [];

        console.log(logType, ' (Last 20) RSIscore:', last20RSI);
        console.log(logType, ' (Last 20) macdScore:', last20macDScore);
        console.log(logType, ' (Last 20) adScore:', last20adScore);
        console.log(logType, ' (Last 20) totalScore:', totalScoreLast20);
      }

      if (!totalScore.get) {
        return defaultBarValue;
      }
      if (Number.isNaN(totalScore.get()) || Number.isNaN(totalScore.get(1))) {
        return defaultBarValue;
      }

      const scorevalue = !Number.isNaN(totalScore.get()) ? totalScore.get() : 0;
      // Color Logic
      let scoreColor = 4;

      if (scorevalue >= scoreThreshold) {
        if (scorevalue >= strongThreshold) {
          scoreColor = 1; // Green
        } else {
          scoreColor = 0; // Dark Green
        }
      }

      if (scorevalue <= -scoreThreshold) {
        if (scorevalue <= -strongThreshold) {
          scoreColor = 3; // Red
        } else {
          scoreColor = 2; // Orange
        }
      }

      if (this._showDebugLog) {
        console.log(logType, ' total_score >= strong_threshold (Bright Green):', scorevalue >= strongThreshold);
        console.log(logType, ' total_score >= score_threshold (Dark Green):', scorevalue >= scoreThreshold);
        console.log(logType, ' total_score <= -strong_threshold (Red):', scorevalue <= -strongThreshold);
        console.log(logType, ' total_score <= -score_threshold (Orange):', scorevalue <= -scoreThreshold);
        console.log(logType, ' scorevalue:', scorevalue);
        console.log(
          logType,
          ' scoreColor:',
          scoreColor === 0
            ? 'Dark Green'
            : scoreColor === 1
            ? 'Bright Green'
            : scoreColor === 2
            ? 'Orange'
            : scoreColor === 3
            ? 'Red'
            : 'Neutral',
        );
      }

      // Plot Score Histogram
      defaultBarValue[0] = scorevalue;
      defaultBarValue[1] = scoreColor;

      return defaultBarValue;
    };
  },
});
