import React, {useMemo, useCallback, useState, useEffect} from 'react';
import {isEqual, differenceWith, sortBy} from 'lodash';
import {useOutletContext} from 'react-router-dom';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import ScannerTable from './ScannerTable';
import Header from '../Header/Header';
import 'react-datepicker/dist/react-datepicker.css';
import ScannerContext from '../../Context/ScannerContext';
import {updateFilters, updateFilteredData} from '../../Helpers/formatData';
import { stableSort} from '../../Helpers/tableHelpers';

dayjs.extend(utc);
dayjs.extend(timezone);

const Scanner = ({
  group,
  multiScreen,
  optionsColumns,
  optionsAllowed,
  lockedColumns,
  groupName,
  displayChartDisabledMessage,
  currentDate,
  useAI,
  overrideGroupOrder,
  dataTypeOverride,
  earningsSymbols,
}) => {
  const [, dispatch] = useOutletContext();
  const [groupState, setGroupState] = useState(group);
  const [orderedData, setOrderedData] = useState([]);
  const [inView, setInView] = useState([]);
  const [orderByState, setOrderBy] = useState(group?.orderBy);
  const [orderState, setOrder] = useState(group?.order);
  const [rowsPerPageState, setRowsPerPage] = useState(group?.rowsPerPage ?? 100);
  const [pageState, setPage] = useState(group?.page ?? 1);
  const [highlightKeys, setHighlightKeys] = useState(
    group?.dataTypes?.length ? group?.dataTypes.filter((dt) => dt.type === 'number' && !dt?.hideColumn) : [],
  );

  const handleUpdateMultiSelect = useCallback(
    (value, type, field, clearAll = false) => {
      const {searchValue} = groupState;
      const newSearchValueCopy = {...searchValue};
      const findFieldToUpdate = newSearchValueCopy.filters.find((filter) => filter?.key === type);
      if (clearAll) {
        const updatedFilterValues = updateFilters([], type, null, group);
        setGroupState(updatedFilterValues);
        return;
      }
      if (findFieldToUpdate && findFieldToUpdate.value) {
        const updateWith = {
          updatedValue: value
            ? [...findFieldToUpdate.value, field]
            : findFieldToUpdate.value.filter((filter) => filter !== field),
          key: type,
          minMax: null,
          group,
        };
        const updatedFilterValues = updateFilters(updateWith?.updatedValue, type, null, group);
        setGroupState(updatedFilterValues);
      }
    },
    [groupState, group],
  );

  const hasFilters = useCallback(() => {
    const filters = group?.searchValue?.filters;
    const withoutSearch = filters?.filter(
      (filt) =>
        filt?.filterable?.type !== 'search' && filt?.filterable?.type !== 'multiselect' && filt?.type !== 'string',
    );
    const boolAndAdditionalFeatures =
      group?.searchValue?.boolFilters?.filter((field) => field?.fields?.length).length ||
      group?.searchValue?.additionalFilters?.length ||
      group?.searchValue?.bearBullFilters?.length ||
      group?.showBearBullFilter;
    const multiScreenRange =
      multiScreen && withoutSearch?.filter((filter) => filter && filter?.filterable?.type === 'range')?.length;
    return withoutSearch?.length || boolAndAdditionalFeatures || multiScreenRange;
  }, []);

  const handleChangePage = useCallback((event, newPage) => {
    if (!group?.group) return;
    dispatch({type: 'SET_PAGE', payload: {group, page: newPage}});
  }, []);

  const highlightRow = (newRowKey, prevValue, newRowVal, symbol) => {
    const animationClassNameIncrease = `${newRowKey}Increase`;
    const animationClassNameDecrease = `${newRowKey}Decrease`;
    const animationAnimationOutClassName = `${newRowKey}AnimationOut`;
    const currentCell = document.getElementById(symbol);
    if (!currentCell || !currentCell?.classList) return;
    currentCell.classList.remove(animationAnimationOutClassName);
    if (prevValue < newRowVal) {
      currentCell.classList.add(animationClassNameIncrease);
    } else if (prevValue > newRowVal) {
      currentCell.classList.add(animationClassNameDecrease);
    }

    setTimeout(() => {
      const cell = document.getElementById(symbol);
      if (!cell) return;
      cell.classList.remove(animationClassNameIncrease, animationClassNameDecrease);
      cell.classList.add(animationAnimationOutClassName);
    }, 1000);
  };

  useEffect(() => {
    if (!group?.dataTypes) return;
    const keys = group?.dataTypes.filter((dt) => dt.type === 'number' && !dt?.hideColumn);
    if (!isEqual(keys, highlightKeys)) {
      setHighlightKeys(keys);
    }
  }, [groupState?.dataTypes, group?.dataTypes]);

  useEffect(() => {
    if (!group) return;
    if (!isEqual(group?.data, groupState?.data)) {
      const updatedGroup = {
        ...group,
        searchValue: group?.date === groupState?.date ? groupState?.searchValue : group?.searchValue,
      };
      const updatedFilteredData = updateFilteredData(updatedGroup);

      if (!isEqual(updatedFilteredData, groupState?.filteredData)) {
        setGroupState((prev) => ({
          ...group,
          searchValue: group?.date === prev?.date ? prev?.searchValue : group?.searchValue,
          filteredData: updateFilteredData({
            ...group,
            searchValue: group?.date === prev?.date ? prev?.searchValue : group?.searchValue,
          }),
        }));
      }
    }
  }, [group]);

  const contextValue = useMemo(
    () => ({
      group: groupState,
      multiScreen,
      optionsColumns,
      useAI,
      optionsAllowed,
      lockedColumns,
      groupName,
      displayChartDisabledMessage,
      filteredData: groupState?.filteredData ?? group?.filteredData,
      allowedChart: groupState?.allowedChart ?? group?.allowedChart,
      handleUpdateMultiSelect,
      hasFilters,
      handleChangePage,
    }),
    [
      groupState,
      multiScreen,
      optionsColumns,
      useAI,
      optionsAllowed,
      lockedColumns,
      groupName,
      displayChartDisabledMessage,
    ],
  );

  useEffect(() => {
    const keys = Object.keys(groupState?.filteredData ?? {});
    if (!keys.length) {
      setOrderedData([]);
      return;
    }
    const currentData = keys.reduce((acc, key) => [...(acc || []), groupState?.filteredData[key]], []);
    if (!currentData || !currentData.length) {
      setOrderedData([]);
      return;
    }
    const filterType = groupState?.dataTypes?.find((type) => type.key === orderByState);

    const ordered = stableSort(
      currentData,
      orderState,
      orderByState,
      filterType,
      optionsColumns,
      groupState.searchValue,
    );
    if (isEqual(orderedData, ordered)) {
      return;
    }
    setOrderedData(ordered);
  }, [groupState?.filteredData, orderState, orderByState, groupState?.dataTypes, optionsColumns]);

  useEffect(() => {
    if (!orderedData?.length) {
      return;
    }
    const newInView = orderedData.slice(pageState * rowsPerPageState, pageState * rowsPerPageState + rowsPerPageState);
    if (!isEqual(inView, newInView)) {
      // console.log("updating inView");
      const diff = differenceWith(newInView, inView, isEqual);
      if (diff?.length && highlightKeys?.length) {
        // highlight the row values that changed
        diff.forEach((row) => {
          const inViewValue = inView.find((r) => r?.Symbol?.value === row?.Symbol?.value);
          if (!inViewValue) return;
          highlightKeys.forEach((k) => {
            const primaryKey = k?.key;
            if (
              !row[primaryKey] ||
              !inViewValue[primaryKey]?.value ||
              row[primaryKey]?.value === inViewValue[primaryKey]?.value?.value
            )
              return;
            const prevValue = Number(inViewValue[primaryKey]?.value);
            const newRowVal = Number(row[primaryKey]?.value);
            highlightRow(primaryKey, prevValue, newRowVal, row?.Symbol?.value);
          });
        });
      }
      setInView(newInView);
    }
    //  else {
    //   console.log("not updating inView");
    //   const diff = differenceWith(newInView, inView, isEqual);
    //   console.log('diff', diff)
    // }
  }, [orderedData, rowsPerPageState, pageState, highlightKeys]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderByState === property && orderState === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    const filterType = groupState?.dataTypes?.find((type) => type.key === property);
    if (filterType?.key === 'Symbol') {
      setOrderBy(property);
      setOrder(newOrder);
    } else {
      const newOrderElse = orderByState === property && orderState !== 'asc' ? 'asc' : 'desc';
      setOrderBy(property);
      setOrder(newOrderElse);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
  };

  const changePage = (event, newPage) => {
    setPage(newPage);
  };

  // Avoid a layout jump when reaching the last page with empty tableData.
  const emptyRows = useMemo(
    () => (pageState > 0 ? Math.max(0, (1 + pageState) * rowsPerPageState - groupState?.data?.length) : 0),
    [pageState, rowsPerPageState, groupState?.data],
  );
  const count = useMemo(() => Object.keys(groupState?.filteredData || {})?.length ?? 0, [groupState?.filteredData]);
  const noData = useMemo(
    () => !Object.keys(groupState?.filteredData ?? {})?.length && !groupState?.data?.length,
    [groupState?.filteredData, groupState?.data],
  );
  return (
    <ScannerContext.Provider value={contextValue}>
      <div className="scanner-wrap">
        <Header currentDate={currentDate} />
        {group ? (
          <div className="scanner">
            <ScannerTable
              inView={inView}
              emptyRows={emptyRows}
              count={count}
              noData={noData}
              dataTypes={groupState?.dataTypes}
              allowedChart={groupState?.allowedChart}
              groupName={groupState?.group}
              orderedData={orderedData}
              orderState={orderState}
              orderByState={orderByState}
              handleRequestSort={handleRequestSort}
              rowsPerPageState={rowsPerPageState}
              pageState={pageState}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              changePage={changePage}
              overrideGroupOrder={overrideGroupOrder}
              dataTypeOverride={dataTypeOverride}
              earningsSymbols={earningsSymbols}
            />
          </div>
        ) : (
          <div className="scanner"> Loading Scanner... </div>
        )}
      </div>
    </ScannerContext.Provider>
  );
};
export default React.memo(Scanner, (prev, next) => {
  const dataSame = isEqual(prev?.group?.filteredData, next?.group?.filteredData);
  const optionsColumnsSame = isEqual(prev?.optionsColumns, next?.optionsColumns);
  const optionsAllowedSame = isEqual(prev?.optionsAllowed, next?.optionsAllowed);
  const useAISame = prev?.useAI === next?.useAI;
  const lockedColumnsSame = isEqual(prev?.lockedColumns, next?.lockedColumns);
  const groupNameSame = prev?.groupName === next?.groupName;
  const displayChartDisabledMessageSame = prev?.displayChartDisabledMessage === next?.displayChartDisabledMessage;
  const currentDateSame = prev?.currentDate === next?.currentDate;
  const overrideGroupOrderSame = isEqual(prev?.overrideGroupOrder, next?.overrideGroupOrder);
  const dataTypeOverrideSame = isEqual(prev?.dataTypeOverride, next?.dataTypeOverride);
  const earningsSymbolsSame = isEqual(prev?.earningsSymbols, next?.earningsSymbols);
  return (
    dataSame &&
    optionsColumnsSame &&
    optionsAllowedSame &&
    lockedColumnsSame &&
    groupNameSame &&
    displayChartDisabledMessageSame &&
    currentDateSame &&
    useAISame &&
    overrideGroupOrderSame &&
    dataTypeOverrideSame &&
    earningsSymbolsSame
  );
});

// export default Scanner;
