import React, {useEffect, useRef, useState, useCallback} from 'react';
import isEqual from 'lodash/isEqual';
import squeezeProIndicator from '../../Helpers/customIndicators/SqueezePro/SqueezePro';
import {
  MovingAverages8EMA,
  MovingAverages21EMA,
  MovingAverages55EMA,
} from '../../Helpers/customIndicators/MovingAverages/movingAverages';
import {GenesisCogMain, GenesisCogLower} from '../../Helpers/customIndicators/Genesis/genesis';
import {UDFCompatibleDatafeedWithStreaming} from '../../Helpers/datafeed';

const moment = require('moment-timezone');

const TradingViewChart = ({
  symbolToChart,
  groupToChart,
  groupCollection,
  theme,
  wsSocket,
  layout,
  hasColumnsToChart,
  supportedResolutions,
  scannerTitle,
  timeFrames,
  currentDate,
}) => {
  const chartRef = useRef(null);
  const container = useRef(null);
  const chartFrameRef = useRef(null);
  const drawingsOnChart = useRef(null);

  const [chartSymbol, setChartSymbol] = useState(null);
  const [chart, setChart] = useState(null);
  const [drawings, setDrawings] = useState([]);
  const [chartReady, setChartReady] = useState(false);
  const [symbolColumnData, setSymbolColumnData] = useState(null);
  const [currentInterval, setCurrentInterval] = useState(null);
  const [plots, setPlots] = useState({plots: [], pricelines: null, conditionalDrawings: [], symbol: null});

  chartFrameRef.current = chart;
  drawingsOnChart.current = drawings;

  const findGroupSettings = (groupID = 'MTA_DPL') => {
    if (!groupCollection || !groupID) return null;
    const groupKeys = Object.keys(groupCollection ?? {});
    if (!groupKeys.length) return null;
    const groupSettings = groupCollection[groupKeys.find((key) => key === groupID)]?.settings;
    if (!Object.keys(groupSettings ?? {}).length) return null;
    return groupSettings;
  };

  const getFirstSymbol = () => {
    if (symbolToChart) return;
    if (!groupCollection || !groupToChart) return;
    const groupKeys = Object.keys(groupCollection ?? {});
    if (!groupKeys.length) return;
    const groupData = groupCollection[groupKeys.find((key) => key === groupToChart)]?.data;
    if (!Object.keys(groupData ?? {}).length) return;
    const groupDataKeys = Object.keys(groupData);
    if (!groupDataKeys.length) return;
    const firstSymbol = groupDataKeys.sort()[0] ?? null;
    if (!firstSymbol) return;
    setChartSymbol(`${firstSymbol}`);
  };

  useEffect(() => {
    getFirstSymbol();
  }, []);
  useEffect(() => {
    getFirstSymbol();
  }, [groupCollection[groupToChart]?.selectedDate, groupCollection[groupToChart]?.data, currentDate]);

  useEffect(() => {
    if (!symbolToChart || !groupToChart) return;
    const newSymbol = `${symbolToChart}`;
    if (newSymbol === chartSymbol) return;
    if (!layout || layout !== 'mw-layout') {
      setChartSymbol(newSymbol);
    } else {
      if (!window.tvWidget || !window.tvWidget.setSymbol) return;
      const settings = findGroupSettings(groupToChart);
      if (symbolToChart === chartSymbol) return;
      window.tvWidget.setSymbol(newSymbol, settings?.groupInfo?.defaultResolution ?? '1D', () => {
        setChartSymbol(newSymbol);
      });
    }
  }, [symbolToChart]);

  const initializeCustomIndicators = useCallback((pjs) => {
    const customGetter = Promise.resolve([
      squeezeProIndicator(pjs),
      MovingAverages8EMA(pjs),
      MovingAverages21EMA(pjs),
      MovingAverages55EMA(pjs),
      GenesisCogMain(pjs),
      GenesisCogLower(pjs),
    ]);
    return customGetter;
  }, []);

  const initializeChart = useCallback(() => {
    if (!chartSymbol) {
      console.log('no Chart Symbol');
      return;
    }
    const url = new URL(window.location.href);
    let token = url.searchParams.get('token');
    if (!token) {
      token = localStorage.getItem('scanner-sso') ?? null;
    }
    const chartSetup = {
      container: 'chartContainer',
      locale: 'en',
      library_path: `${window.location.origin}/charting_library/`,
      datafeed: new UDFCompatibleDatafeedWithStreaming(
        process.env.REACT_APP_CHART_URL ?? 'http://localhost/chart',
        wsSocket,
        token,
        layout ?? 'tabs-layout',
        scannerTitle,
        supportedResolutions ?? [],
        layout === 'mw-layout' ? 45 * 1000 : 60 * 1000,
        {maxResponseLength: 1000},
        process.env.REACT_APP_HISTORY_URL ?? 'http://66.85.131.10:8080/chart',
      ),
      symbol: chartSymbol,
      interval: '1D',
      fullscreen: false,
      // debug: process.env.NODE_ENV === 'development',
      autosize: true,
      timezone: 'America/New_York',
      theme: theme ?? 'light',
      allow_symbol_change: false,
      timeframe: '1D',
      enabled_features: ['request_only_visible_range_on_reset'],
      disabled_features: ['header_symbol_search'],
      overrides: {
        'mainSeriesProperties.style': 8,
        'paneProperties.topMargin': 20,
      },
      custom_indicators_getter: (pjs) => initializeCustomIndicators(pjs),
    };
    if (timeFrames?.length) {
      chartSetup.time_frames = timeFrames;
    }
    // eslint-disable-next-line new-cap
    const newChart = new window.TradingView.widget(chartSetup);
    window.tvWidget = newChart;
    setChart(newChart);
  }, [wsSocket, chartSymbol, layout, scannerTitle, supportedResolutions, theme, timeFrames]);

  useEffect(() => {
    if (!window.TradingView || !chartSymbol || !window.Datafeeds) return;
    if (layout && layout === 'mw-layout' && chart) {
      return;
    }
    initializeChart();
  }, [chartSymbol]);

  useEffect(() => {
    console.log('TradingViewChart component mounted');
    return () => {
      console.log('TradingViewChart component unmounted');
      setChart(null);
      setChartReady(false);
      window.tvWidget = null;
    };
  }, []);

  const drawOnChart = useCallback(
    (
      prevDrawings,
      chrt,
      currentResolution = null,
      plts = [],
      pricelines = null,
      conditionalDrawings = null,
      chrtSym = null,
    ) => {
      if (!chrt) return;
      if (!plts?.length) {
        chrt
          .activeChart()
          .onDataLoaded()
          .subscribe(
            null,
            () => {
              chrt.activeChart().removeAllShapes();
              // removeDrawings(prevDrawings, chrt);
              setDrawings([]);
            },
            true,
          );
        return;
      }
      let reso = currentResolution;
      if (!reso) {
        reso = chrt.activeChart().resolution();
      }
      const currentResolutionDrawings = plts.filter((plot) => plot.resolution === reso && plot.plots.length);
      const currentResolutionPricelines = pricelines?.[reso] ?? [];
      const currentResolutionConditionalDrawings = conditionalDrawings?.filter(
        (plot) => plot.resolution === reso && plot.plots.length,
      );
      if (currentResolutionDrawings?.length || currentResolutionPricelines?.length) {
        chrt.activeChart().dataReady(() => {
          chrt.activeChart().removeAllShapes();
          if (currentResolutionDrawings?.length) {
            const onChartDrawings = currentResolutionDrawings.map((plot) => {
              const drwings = [];
              if (plot?.plots?.length) {
                plot.plots.forEach((p, i) => {
                  let points1 = [];
                  let points2 = [];
                  let point1 = null;
                  let point2 = null;

                  if (!plot.plots[i + 1]) {
                    point1 = p;
                    points1 = [point1, point1];
                    points2 = [];
                  } else {
                    point1 = p;
                    point2 = plot.plots[i + 1];
                    points1 = [point1, point1];
                    points2 = [point1, point2];
                  }
                  const newDrawing1 = chrt.activeChart().createMultipointShape(points1, plot.meta);
                  drwings.push(newDrawing1);
                  if (!points2?.length) {
                    return;
                  }
                  const newDrawing2 = chrt.activeChart().createMultipointShape(points2, plot.meta);
                  drwings.push(newDrawing2);
                });
              }
              return drwings;
            });
            setDrawings(onChartDrawings);
          }
          if (currentResolutionConditionalDrawings?.length) {
            currentResolutionConditionalDrawings.map((plot) => {
              const drwings = [];
              if (plot?.plots?.length) {
                plot.plots.forEach((p, i) => {
                  let points1 = [];
                  let points2 = [];
                  let point1 = null;
                  let point2 = null;

                  if (!plot.plots[i + 1]) {
                    point1 = p;
                    points1 = [point1, point1];
                    points2 = [];
                  } else {
                    point1 = p;
                    point2 = plot.plots[i + 1];
                    points1 = [point1, point1];
                    points2 = [point1, point2];
                  }
                  const newDrawing1 = chrt.activeChart().createMultipointShape(points1, plot.meta);
                  drwings.push(newDrawing1);
                  if (!points2?.length) {
                    return;
                  }
                  const newDrawing2 = chrt.activeChart().createMultipointShape(points2, plot.meta);
                  drwings.push(newDrawing2);
                });
              }
              return drwings;
            });
          }
          if (currentResolutionPricelines?.length) {
            const onChartPricelines = currentResolutionPricelines.map((priceline) => {
              if (priceline?.value) {
                return chrt.activeChart().createShape(priceline?.value, priceline?.meta);
              }
              return null;
            });
          }
          // console.log("plots: plts, pricelines, symbol: chrtSym", {plots: plts, pricelines, symbol: chrtSym})
          setSymbolColumnData({plots: plts, pricelines, symbol: chrtSym});
        });
        return;
      }

      chrt.activeChart().dataReady(() => {
        chrt.activeChart().removeAllShapes();

        setDrawings([]);
        setSymbolColumnData({plots: [], pricelines: null, symbol: chrtSym});
      });
    },
    [chartReady, chart, symbolColumnData],
  );

  const handleIntervalChange = useCallback(
    (interval, timeframeObj) => {
      setCurrentInterval(interval);
    },
    [chart, symbolColumnData],
  );

  useEffect(() => {
    if (!chart || chartReady) return;
    if (layout && layout === 'mw-layout') {
      chart.onChartReady(async () => {
        const settings = findGroupSettings(groupToChart);
        const defaultRes = settings?.groupInfo?.defaultResolution ?? '1D';
        const indicators = settings?.groupInfo?.indicators ?? [];
        if (indicators?.length) {
          await Promise.all(
            indicators.map(async (indicator) => {
              const {name, mainPane, lock, inputs, overrides, options} = indicator;
              const id = await chart.activeChart().createStudy(name, mainPane, lock, inputs, overrides, options);
              const api = chart.activeChart().getStudyById(id);
              if (!window.indicatorApis) window.indicatorApis = {};
              window.indicatorApis[name] = api;
              console.log('api', api);
              return id;
            }),
          );
        }
        chart.activeChart().setResolution(defaultRes);
        if (defaultRes === '1D') {
          const twoMonthsAgo = moment().subtract(1, 'months').subtract(15, 'days').unix();
          chart.activeChart().setVisibleRange({from: twoMonthsAgo}, {percentRightMargin: 10});
        }
        chart.activeChart().onIntervalChanged().subscribe(null, handleIntervalChange);
        setChart(chart);
        setChartReady(true);
      });
      return;
    }
    chart.onChartReady(async () => {
      setChartReady(true);
      // Squeeze Pro Indicator
      const SPIIndicator = await chart.activeChart().createStudy('Squeeze PRO Indicator', false, false);
      const SPIApi = chart.activeChart().getStudyById(SPIIndicator);

      // EMAs
      await chart.activeChart().createStudy('Moving Averages 8 EMA', true, false);
      await chart.activeChart().createStudy('Moving Averages 21 EMA', true, false);
      await chart.activeChart().createStudy('Moving Averages 55 EMA', true, false);

      // Volume Indicator
      await chart.activeChart().createStudy('Volume', true, false);

      // VRVP Indicator
      const VPVRId = await chart.activeChart().createStudy('Volume Profile Visible Range', true, false);
      const VPVRApi = chart.activeChart().getStudyById(VPVRId);
      VPVRApi.applyOverrides({
        'graphics.hhists.histBars2.visible': true,
        'graphics.hhists.histBarsVA.visible': true,
        'graphics.hhists.histBars2.transparencies': [76, 76],
        'graphics.hhists.histBarsVA.transparencies': [76, 76],
      });

      const settings = findGroupSettings(groupToChart);
      const defaultRes = settings?.groupInfo?.defaultResolution ?? '1D';
      if (defaultRes === '1D') {
        const twoMonthsAgo = moment().subtract(3, 'months').unix();
        chart.activeChart().setVisibleRange({from: twoMonthsAgo}, {percentRightMargin: 20});
      }
      chart.activeChart().setResolution(defaultRes);

      SPIApi.onDataLoaded().subscribe(
        null,
        () => {
          const allHeights = chart.activeChart().getAllPanesHeight();
          if (!allHeights?.length) {
            return;
          }
          const totalHeight = allHeights.reduce((acc, height) => acc + height, 0);
          const removeFirst2Heights = allHeights?.length > 2 ? allHeights.slice(2) : [];
          chart.activeChart().setAllPanesHeight([totalHeight * 0.8, totalHeight * 0.2, ...removeFirst2Heights]);
        },
        true,
      );
    });
  }, [chart]);

  useEffect(() => {
    const newPlots = groupCollection[groupToChart]?.data[chartSymbol]?.plots ?? [];
    const newPricelines = groupCollection[groupToChart]?.data[chartSymbol]?.pricelines ?? null;
    const newConditionalDrawings = groupCollection[groupToChart]?.data[chartSymbol]?.conditionalDrawings ?? null;
    if (
      chartSymbol === plots?.symbol &&
      isEqual(newPlots, plots?.plots) &&
      isEqual(newPricelines, plots?.pricelines) &&
      isEqual(newConditionalDrawings, plots?.conditionalDrawings)
    ) {
      // console.log('Not updating chart, data the same');
      return;
    }
    setPlots({
      plots: newPlots,
      pricelines: newPricelines,
      symbol: chartSymbol,
      conditionalDrawings: newConditionalDrawings,
    });
  }, [groupCollection, groupToChart, symbolToChart, chartSymbol]);

  useEffect(() => {
    if (!hasColumnsToChart || !chartReady || !chart) return;
    if (chart.activeChart) {
      drawOnChart(
        drawings,
        chart,
        currentInterval,
        plots?.plots ?? [],
        plots?.pricelines ?? {},
        plots?.conditionalDrawings ?? [],
        chartSymbol,
      );
    }
  }, [plots, chart, currentInterval, chartReady]);

  // useEffect(() => {
  //   if (!groupCollection || !groupToChart || !chart || !chartReady) return;
  //   removeAllDrawings(chart);
  // }, [groupCollection[groupToChart]?.selectedDate]);
  // Handle Drawing on Chart End

  return (
    <div
      className="tradingview-widget-container"
      ref={container}
      style={{
        height: layout !== 'mw-layout' ? 'calc(100% - 30px)' : '100%',
        width: '100%',
        marginTop: layout !== 'mw-layout' ? '10px' : '0px',
      }}
    >
      <div id="chartContainer" ref={chartRef} style={{height: '100%', width: '100%'}} />
    </div>
  );
};

export default React.memo(TradingViewChart, (prev, next) => {
  const symbolSame = prev.symbolToChart === next.symbolToChart;
  const groupToChartSame = prev.groupToChart === next.groupToChart;
  let dataSame = false;
  if (
    prev?.groupCollection &&
    next?.groupCollection &&
    prev?.groupToChart &&
    next?.groupToChart &&
    prev?.symbolToChart &&
    next?.groupToChart
  ) {
    const prevGroupInGroupCol = prev.groupCollection?.[prev.groupToChart];
    const nextGroupInGroupCol = next.groupCollection?.[next.groupToChart];
    if (
      prevGroupInGroupCol &&
      nextGroupInGroupCol &&
      Object.keys(prevGroupInGroupCol?.data ?? {}).length &&
      Object.keys(nextGroupInGroupCol?.data ?? {}).length
    ) {
      const symbolDataInGroupCol = prevGroupInGroupCol?.data[prev.symbolToChart];
      const nextSymbolDataInGroupCol = nextGroupInGroupCol?.data[next.symbolToChart];
      if (symbolDataInGroupCol && nextSymbolDataInGroupCol) {
        dataSame = JSON.stringify(symbolDataInGroupCol) === JSON.stringify(nextSymbolDataInGroupCol);
      }
    }
  } else {
    if (prev?.groupCollection && next?.groupCollection && prev?.groupToChart && next?.groupToChart) {
      dataSame =
        JSON.stringify(prev.groupCollection?.[prev.groupToChart] ?? {}) ===
        JSON.stringify(next.groupCollection?.[next.groupToChart] ?? {});
    }
    dataSame = JSON.stringify(prev.groupCollection) === JSON.stringify(next.groupCollection);
  }
  const themeSame = prev.theme === next.theme;
  const layoutSame = prev.layout === next.layout;
  const resolutionsSame = JSON.stringify(prev.supportedResolutions) === JSON.stringify(next.supportedResolutions);
  const scannerTitleSame = prev.scannerTitle === next.scannerTitle;
  const timeFramesSame = JSON.stringify(prev.timeFrames) === JSON.stringify(next.timeFrames);
  const hasColumnsToChartSame = prev.hasColumnsToChart === next.hasColumnsToChart;
  const currentDateSame = prev.currentDate === next.currentDate;
  return (
    symbolSame &&
    groupToChartSame &&
    dataSame &&
    themeSame &&
    layoutSame &&
    resolutionsSame &&
    scannerTitleSame &&
    timeFramesSame &&
    hasColumnsToChartSame &&
    currentDateSame
  );
});

// export default TradingViewChart;
